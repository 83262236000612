
import axios from "axios";

const getHeaders = () => {
    return {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`
    }
}

const endpoint = (uri) => {
    return `${process.env.VUE_APP_API_HOST}/api/${uri}`;
}

export function get(uri) {
	return axios.get(endpoint(uri), {
		headers: getHeaders(),
	});
}


export function post(uri, data) {
	return axios.post(endpoint(uri), data, {
		headers: getHeaders(),
	});
}
   
   