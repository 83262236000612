<template>
	<div class="home" :class="{ 'verticalsly-centered': isMobile }">
		<div class="columns pt-6 is-mobile is-vcentered">
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<div class="column" :class="{ 'pl-0 pr0': !isMobile }">
					<div class="column is-offset-2">
						<figure v-if="$i18n.locale == 'en'">
							<img :src="back_image_en" />
						</figure>
						<figure v-else>
							<img :src="back_image_pt" />
						</figure>
					</div>
					<div class="info-div">
						<h1 class="info">
							{{ $t('views.HomePage.Ciao.front_title') }}<span class="border-bottom-lime">{{ $t('views.HomePage.Ciao.underline_text') }}</span>
						</h1>
						<div class="mt-6">
							<h2 class="has-text-grey-light has-text-weight-light info-bottom">{{ $t('views.HomePage.Ciao.secondary_text') }}</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br />
		<br />
		<div class="columns">
			<div class="column bottom-link-div pb-6 mt-6 is-3 is-offset-7 pr-5" style="text-align: right">
				<p class="is-clickable" @click="goToLocations()">{{ $t('views.HomePage.Ciao.link_text') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Ciao',
	data() {
		return {
			isMobile: window.innerWidth <= 1023,
			back_image_en: require('@/assets/img/ciao.png'),
			back_image_pt: require('@/assets/img/holla.png'),
		};
	},
	methods: {
		goToLocations() {
			this.$router.push({
				path: '/locations',
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';

section {
	//min-height: 92vh;
	//background-color: $primary;
	position: relative;
	/*@media only screen and (min-width: 551px) {
		min-height: 88vh;
	}
	@media only screen and (max-width: 550px) {
		min-height: 115vh;
	}

	@media only screen and (max-width: 350px) {
		min-height: 100vh;
	}*/
	@media only screen and (min-width: 1024px) {
		min-height: 88vh;
	}
}

.home .info {
	color: var(---grey-light);
	text-align: left;
	//font: normal normal bold 112px/129px Roboto;
	//font-size: 100px;
	font-weight: bold;
	letter-spacing: 0px;
	color: $grey-light;
	opacity: 1;

	@media only screen and (min-width: 1130px) {
		font-size: 100px;
	}

	@media only screen and (max-width: 1129px) {
		font-size: 85px;
	}

	@media only screen and (max-width: 950px) {
		font-size: 80px;
	}

	@media only screen and (max-width: 900px) {
		font-size: 75px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 65px;
	}

	@media only screen and (max-width: 570px) {
		font-size: 55px;
	}
	@media only screen and (max-width: 450px) {
		font-size: 50px;
	}
	@media only screen and (max-width: 400px) {
		font-size: 45px;
	}
	@media only screen and (max-width: 350px) {
		font-size: 35px;
	}
}

.info-bottom {
	@media only screen and (min-width: 1130px) {
		font-size: 50px;
	}

	@media only screen and (max-width: 1129px) {
		font-size: 42.5px;
	}

	@media only screen and (max-width: 950px) {
		font-size: 40px;
	}

	@media only screen and (max-width: 900px) {
		font-size: 37.5px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 32.5px;
	}

	@media only screen and (max-width: 570px) {
		font-size: 27.5px;
	}
	@media only screen and (max-width: 450px) {
		font-size: 25px;
	}
	@media only screen and (max-width: 400px) {
		font-size: 22.5px;
	}
	@media only screen and (max-width: 350px) {
		font-size: 17.5px;
	}
}

/*.info-div {
	position: absolute;
	//padding-bottom: 50px;
	top: 250px;
	left: 14%;
	right: 8%;
}*/
/*
.info-div {
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}*/

.background-text {
	color: $black;
	text-align: left;
	//font-size: 400px;
	letter-spacing: 0px;

	@media only screen and (min-width: 1141px) {
		font-size: 400px;
	}

	@media only screen and (max-width: 1140px) {
		font-size: 350px;
	}

	@media only screen and (max-width: 1020px) {
		font-size: 300px;
	}
}

.border-bottom-lime {
	//border-bottom: 4px solid $lime;
	text-decoration: underline;
	text-decoration-color: $lime;
	text-decoration-style: solid;
	text-decoration-thickness: 0.3rem;
	text-underline-offset: 4px;
}

.bottom-link-div * {
	color: $lime;
}

.bottom-link-div p::before {
	display: inline-block;
	content: '';
	border-top: 1px solid $lime;
	width: 4rem;
	margin: 0 1rem;
	transform: translateY(-4px);
}
</style>