import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import i18n from './i18n';
import VueGoogleCharts from 'vue-google-charts';
import VueGtag from 'vue-gtag';

Vue.use(Buefy, {
	customIconPacks: {
		fv: {
			iconPrefix: 'fv-',
		},
	},
});

if (process.env.NODE_ENV === 'production') {
	Vue.use(
		VueGtag,
		{
			config: {
				id: process.env.VUE_APP_GA,
			},
		},
		router
	);
}

Vue.use(VueGoogleCharts);

import './mixins/index';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
