<template>
	<!--<section class="has-background-primary">-->
	<div class="columns is-mobile is-vcentered">
		<div class="column is-8-desktop is-offset-2-desktop">
			<div class="column" :class="{ 'pl-0 pr-0': !isMobile }">
				<h2 class="has-text-grey-light has-text-weight-light is-size-3 title-custom-underline mb-6">
					<span class="has-text-weight-bold">{{ $t('components.Skills.title') }} </span> {{ $t('components.Skills.subtitle') }}
				</h2>
				<ul class="custom-list">
					<div
						class="is-clickable"
						@click="emitToParent(category.id)"
						v-for="(category) in categories"
						:key="category.id"
						@mouseover="mouseOver(category.id)"
						@mouseleave="mouseLeave()"
					>
						<li>
							<div v-if="hover_category != category.id">
								<span  class="main-li is-size-1-desktop is-size-3-touch">{{ category.attributes.title }}</span>
							</div>
							<div v-else>
								<span class="has-text-grey is-size-5">
									{{  category.attributes.subtitle }}
								</span>
								<br />
								<div class="mt-2">
									<span class="keep-reading has-text-lime"> {{ $t('components.Skills.link_text') }}</span>
								</div>
							</div>
						</li>
						<hr class="has-background-lime" />
					</div>
				</ul>
			</div>
		</div>
	</div>
	<!--</section>-->
</template>

<script>


export default {
	name: 'Skill',
	props: {
		categories: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			x: null,
			isMobile: window.innerWidth <= 1023,
			hover_category: null
		};
	},
	created() {},
	methods: {
		mouseOver(cat) {
			this.hover_category = cat;
		},
		mouseLeave() {
			this.hover_category = null;
		},
		emitToParent(category_id) {
			this.$emit('childEmit', category_id);
		}
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';

@media only screen and (min-width: 1024px) {
	section {
		min-height: 92vh;
	}
}
/*
section {
	
	//background-color: $primary;
	@media only screen and (min-width: 935px) {
		min-height: 88vh;
	}
	@media only screen and (max-width: 934px) {
		min-height: 120vh;
	}
	@media only screen and (max-width: 768px) {
		min-height: 105vh;
	}
	@media only screen and (max-width: 630px) {
		min-height: 110vh;
	}
	@media only screen and (max-width: 630px) {
		min-height: 120vh;
	}
	@media only screen and (max-width: 510px) {
		min-height: 130vh;
	}
	@media only screen and (max-width: 415px) {
		min-height: 145vh;
	}
	@media only screen and (max-width: 350px) {
		min-height: 155vh;
	}
}*/

.custom-list .main-li {
	-webkit-text-stroke: 1px $lime;
	color: transparent;
	//font-size: 50px;

	font-weight: 800;
}

.title-custom-underline::after {
	content: '';
	display: block;
	width: 3rem;
	padding-top: 0.5%;
	border-bottom: 0.3rem solid $lime;
}

.keep-reading::before {
	display: inline-block;
	content: '';
	border-top: 1px solid $lime;
	width: 4rem;
	///margin: 0 1rem;
	transform: translateY(-4px);
}
</style>